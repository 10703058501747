import React from 'react';
import {BrowserRouter, Switch, Route, Redirect, withRouter} from 'react-router-dom';
import Home3 from '../pages/Home3';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
      // Agrega los estilos del contenedor principal aquí
      outline: 'none', // Elimina el contorno blanco
    },
}));

function App() {
  const classes = useStyles();
    return (
      <div className={classes.root}>
        <BrowserRouter >
          <Switch>
            <Route path="/" component={Home3}/>
            <Route path="*" render={() => <Redirect to="/" />} />
          </Switch>
        </BrowserRouter>
      </div>  
    );
}

export default App ;
