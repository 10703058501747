import React from 'react';
import ReactDOM from 'react-dom';
import Routes from './routes/Routes';


ReactDOM.render(
  <div >
    <React.StrictMode>
      <Routes />
    </React.StrictMode>
  </div>,
  document.getElementById('root')
);