import React, { useState, useEffect } from 'react';

import {makeStyles} from '@material-ui/core/styles'
import webLoading from '../assets/img/web_loading.jpg';
import webLoadingMobile from '../assets/img/web_loading_mobile.jpg';
import buttonFacebook from '../assets/img/facebook_icon.png'; // Ruta a tu primera imagen de botón
import buttonInstagram from '../assets/img/insta_icon.png'; // Ruta a tu segunda imagen de botón
import buttonLinkedin from '../assets/img/linkedin_icon.png'; // Ruta a tu tercera imagen de botón
import buttonTiktok from '../assets/img/tiktok_icon.png'; // Ruta a tu cuarta imagen de botón


const useStyles = makeStyles((theme)=>({
    root:{
        flexGrow: 1,
        background: "#000",
        color: "#FFF",
        overflowX: "hidden",
        margin: "-8px",
        fontFamily: "'Helvetica', Arial, sans-serif;",
        //padding: "30px"
    },
    buttonContainer: {
      position: 'absolute',
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      bottom: '0%', // Valor por defecto para pantallas más grandes

      // Media query para pantallas pequeñas
      [theme.breakpoints.down('sm')]: {
        bottom: '33%', // Ajusta la posición inferior para pantallas pequeñas
      },

      // Media query para pantallas medianas
      [theme.breakpoints.between('sm', 'md')]: {
        bottom: '20%', // Ajusta la posición inferior para pantallas medianas
      },

      // Media query para pantallas grandes
      [theme.breakpoints.up('md')]: {
        bottom: '0%', // Ajusta la posición inferior para pantallas grandes
      },

      // Media query específica para iPhone SE (375px de ancho y 667px de alto)
      '@media (max-width: 376px) and (max-height: 668px)': {
        bottom: '18%', // Ajusta la posición específica para iPhone SE
      },
    },
    button: {
      margin: '0 10px', // Espacio entre los botones
      cursor: 'pointer',
      width: '50px', // Ajusta el tamaño de los botones según tus necesidades
      height: '50px',
      transition: 'transform 0.3s', // Efecto de hover
      '&:hover': {
        transform: 'scale(1.1)', // Agranda un poco el botón al hacer hover
      },

      // Media query para pantallas pequeñas
      [theme.breakpoints.down('sm')]: {
        width: '30px', // Tamaño reducido para pantallas pequeñas
        height: '30px',
      },

      // Media query para pantallas medianas
      [theme.breakpoints.between('sm', 'md')]: {
        width: '40px', // Tamaño intermedio para pantallas medianas
        height: '40px',
      },

      // Media query para pantallas grandes
      [theme.breakpoints.up('md')]: {
        width: '50px', // Tamaño original para pantallas grandes
        height: '50px',
      },
    },
}));

const handleButtonClick = (url) => {
  window.open(url, '_blank');
};

const facebookUrl = "https://www.facebook.com/profile.php?id=61561431143520&sk=about"
const instagramUrl = "https://www.instagram.com/cassas.pe?igsh=NnhhYzhuOWk3a2Fh"
const linkedinUrl = "https://www.linkedin.com/company/cassas/"
const tiktokUrl = "https://www.tiktok.com/@cassas.pe"

export default function Home3 (){
    const classes = useStyles();
    const [imageUrl, setImageUrl] = useState(webLoading);

    const updateImage = () => {
      if (window.innerWidth <= 768) {
        setImageUrl(webLoadingMobile); // Imagen para móviles
      } else {
        setImageUrl(webLoading); // Imagen para pantallas grandes
      }
    };

    // Ejecutar cuando el componente se monta y en cada cambio de tamaño de pantalla
    useEffect(() => {
      updateImage();
      window.addEventListener('resize', updateImage);
      return () => window.removeEventListener('resize', updateImage);
    }, []);
    
    return (
    <div className={classes.root}>
      <img src={imageUrl} alt="Full Screen" width={"100%"} height={"100%"} margin={0} padding={0}/>
      <div className={classes.buttonContainer}>
        <img src={buttonInstagram} alt="Button 2" className={classes.button} onClick={() => handleButtonClick(instagramUrl)} />
        <img src={buttonTiktok} alt="Button 4" className={classes.button} onClick={() => handleButtonClick(tiktokUrl)} />
        <img src={buttonFacebook} alt="Button 1" className={classes.button} onClick={() => handleButtonClick(facebookUrl)} />
        <img src={buttonLinkedin} alt="Button 3" className={classes.button} onClick={() => handleButtonClick(linkedinUrl)} />
      </div>
    </div>
    )
    
}